// agencies pictures upload for website homepage carousel

import { Controller } from "@hotwired/stimulus"
// import Rails from "@rails/ujs";

// Connects to data-controller="upload"
export default class extends Controller {
    static targets = ["fileField"];

    connect() {
        // console.log("UploadController connected");
    }

    submit(event) {
        event.preventDefault();
        let file = this.fileFieldTarget.files[0];
        let formData = new FormData();
        formData.append("pictures", file);

        fetch(`/admin/agences/${this.element.dataset.agencyId}/upload_image`, {
            method: "POST",
            body: formData,
            headers: {
                'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content'),
                'Accept': 'text/vnd.turbo-stream.html',
                'Turbo-Frame': 'images-list'
            },
            credentials: 'same-origin'
        }).then(response => {
            console.log("Image uploaded successfully");
            return response.text();
        }).then(html => {
            Turbo.renderStreamMessage(html);
        }).catch(error => {
            console.error("Error uploading image");
        });
    }
}