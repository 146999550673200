/* display page elements with animate */
$( document ).ready(function() {
    $('#home, #theme, .menu, .content-details, #content-contact, .content-project, footer').animate({'opacity': '1'}, 1000);

    /* website sidebar */
    $('#menuToggle, .menu-close, #scrollToProjects').on('click', function(){
        $('#menuToggle').toggleClass('active');
        $('body').toggleClass('body-push-toleft');
        $('#vba_menu').toggleClass('menu-open');
    });

    /* scrollTo */
    $('.scrollTo').click( function() {
        var page = $(this).attr('href');
        var speed = 1000;
        $('html, body').animate( { scrollTop: $(page).offset().top }, speed );
        return false;
    });

    /* display home element */
    if (window.location.href.indexOf("#") > -1) {

    }
    else {
        /* run the AJAX function */
        if ($(window).width() > 768) {
            $("#vba #content-domains, #vba #content-projects, #vba #content-agency, #vba #footer").addClass("fadeSection");
            // Every time the window is scrolled ...
            $(window).scroll(function () {
                //Check the location of each desired element
                $('#vba #home.fadeSection, #vba #theme.fadeSection, #vba #content-domains.fadeSection, #vba #content-projects.fadeSection, #vba #content-agency.fadeSection, #vba footer.fadeSection').each(function (i) {
                    var bottom_of_object = $(this).position().top + ( $(this).outerHeight() / 6 );
                    var bottom_of_window = $(window).scrollTop() + $(window).height();
                    //If the object is completely visible in the window, fade it in
                    if (bottom_of_window > bottom_of_object) {
                        $(this).animate({'opacity': '1'}, 500);
                    }
                });
            });
        };
    };

    /* parallax */
    $window = $(window);

    $('.parallax[data-type="background"]').each(function () {
        var $scroll = $(this);

        $(window).scroll(function () {
            // HTML5 proves useful for helping with creating JS functions!
            // also, negative value because we're scrolling upwards
            var yPos = -($window.scrollTop() / $scroll.data('speed'));
            // background position
            var coords = '50% ' + yPos + 'px';
            // move the background
            $scroll.css({backgroundPosition: coords});
        });
    });

    /* show project carousel */
    $("#vba #project-gallery button").click(function () {
        window.scrollTo(0, 0);
        $("#project-carousel").fadeIn('slow');
        $("#vba_menu, #project-gallery, #project-details, #project-description, footer").fadeOut(50);

    });

    /* hide project carousel */
    $("#vba #project-carousel button.btn-close").click(function () {
        $("#project-carousel").fadeOut('slow');
        $("#vba_menu, #project-gallery, #project-details, #project-description, footer").fadeIn(50);

    });

    /* adjust footer position if project has no image */
    /*if($.trim($(".carousel-item").html())==''){
        alert("empty")
    }*/

/*    if ($('#project-gallery').height() <= 700) {
        $("html").addClass('footer_bottom');
        $("footer").addClass('position-absolute bottom-0');
    }*/

    $("#go-top a").click(function(){
        $('.carousel-caption').animate({'opacity': '1'}, 500);
    });
    // slides interval
    $('.carousel').carousel({
        interval: 10000
    })

    /* Project carousel */
    // Recognize if image is landscape or portrait
    $(window).on('load', function() {
        $('#project-carousel img').addClass(function() {
            if (this.height === this.width) {
                //return 'square';
            } else if (this.height > this.width) {
                return 'portrait';
            } else {
                //return 'landscape';
            }
        });

        $('.hovereffect img').addClass(function() {
            if (this.height === this.width) {
                //return 'square';
            } else if (this.height > this.width) {
                $(this).next(".overlay").addClass("portrait");
            } else {
                //return 'landscape';
            }
        });

    });

    /* display go top */
    $(document).scroll(function () {
        var y = $(this).scrollTop();
        if (y > 500) {
            $('#go-top').fadeIn();
        } else {
            $('#go-top').fadeOut();
        }
    });

    /* cookies consent */
    $(document).ready(function () {

        if (!$('body').hasClass('admin-page')) {
            // Initialiser tarteaucitron si l'utilisateur n'est pas sur une page admin
            initTarteaucitron();
        }

    });

    function initTarteaucitron() {
        //let tarteaucitronSource = $('#tarteaucitron-script-src').val();
        //$("body").append($("<script></script>").attr("src", tarteaucitronSource));

        //if (typeof tarteaucitron === 'object') {
        tarteaucitron.init({
            "privacyUrl": "", /* Privacy policy url */
            "bodyPosition": "bottom", /* or top to bring it as first element for accessibility */

            "hashtag": "#tarteaucitron", /* Open the panel with this hashtag */
            "cookieName": "tarteaucitron", /* Cookie name */

            "orientation": "bottom", /* Banner position (top - bottom - middle - popup) */

            "groupServices": false, /* Group services by category */
            "showDetailsOnClick": true, /* Click to expand the description */
            "serviceDefaultState": "wait", /* Default state (true - wait - false) */

            "showAlertSmall": false, /* Show the small banner on bottom right */
            "cookieslist": true, /* Show the cookie list */

            //"closePopup": false, /* Show a close X on the banner */

            "showIcon": false, /* Show cookie icon to manage cookies */
            // "iconSrc": "", /* Optionnal: URL or base64 encoded image */
            "iconPosition": "BottomLeft", /* Position of the icon between BottomRight, BottomLeft, TopRight and TopLeft */

            "adblocker": false, /* Show a Warning if an adblocker is detected */

            "DenyAllCta" : true, /* Show the deny all button */
            "AcceptAllCta" : true, /* Show the accept all button when highPrivacy on */
            "highPrivacy": true, /* HIGHLY RECOMMANDED Disable auto consent */
            "alwaysNeedConsent": false, /* Ask the consent for "Privacy by design" services */

            "handleBrowserDNTRequest": false, /* If Do Not Track == 1, disallow all */

            "removeCredit": true, /* Remove credit link */
            "moreInfoLink": false, /* Show more info link */

            "useExternalCss": true, /* If false, the tarteaucitron.css file will be loaded */
            "useExternalJs": false, /* If false, the tarteaucitron.js file will be loaded */

            //"cookieDomain": ".my-multisite-domaine.fr", /* Shared cookie for subdomain website */

            "readmoreLink": "/cookiespolicy", /* Change the default readmore link */

            "mandatory": false, /* Show a message about mandatory cookies */
            "mandatoryCta": true, /* Show the disabled accept button when mandatory on */

            //"customCloserId": "", /* Optional a11y: Custom element ID used to open the panel */

            "googleConsentMode": true, /* Enable Google Consent Mode v2 for Google ads and GA4 */

            "partnersList": false /* Show the number of partners on the popup/middle banner */
        });

        tarteaucitron.user.gtagUa = 'G-BN3L50YNPH';
        // tarteaucitron.user.gtagCrossdomain = ['example.com', 'example2.com'];
        tarteaucitron.user.gtagMore = function () { /* add here your optionnal gtag() */ };
        (tarteaucitron.job = tarteaucitron.job || []).push('gtag');

        //}
    };


});

/* display flash notice modal on home page */
$(window).on('load', function () {
    $('#websiteIndexModal').modal('show');
});

