import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="recaptcha-v3"
export default class extends Controller {
  connect() {
    let siteKey = document.querySelector('meta[name="recaptcha-site-key-v3"]').content;
    grecaptcha.ready(() => {
      // console.log('grecaptcha is ready'); // point 1 - grecaptcha api has loaded
      grecaptcha.execute(siteKey, {action: 'submit'}).then((token) => {
        //console.log('Token received: ' + token); // point 2 - token has been received
        this.callbackFunction(token);
      });
    });
  }

  callbackFunction(token) {
    const element = document.getElementById('recaptchaResponse');
    if (element) {
      element.value = token;
      //console.log('Element exists and token assigned: '+ element.value); // point 3 - Element assigned the token value
    } else {
      //console.log('Element does not exist'); // alert when element doesn't exist
    }

  }
}
