// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import "./import_jquery"
import * as bootstrap from "bootstrap"
import "@fortawesome/fontawesome-free/js/all";

document.addEventListener("turbo:load", function() {
    /* Tooltip */
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
    })
});
import "trix"
import "@rails/actiontext"
import "./richtext"
import "./website"

/* Contact type */

$(document).ready(function() {
    // Checks contact status value
    var status = $('#contact-type').val();
    if(status === "") {
        $("#contact-actions").hide();
        $("#contact-part").hide();
        $("#com-consent").hide();
        $("#contact-pro").hide();
        $("#pro-com-consent").hide();
        $("#main-contact-form").removeClass("col-xl-7");
    }
    else if (status === "Particulier") {
        $("#contact-actions").show();
        $("#contact-part").show();
        $("#com-consent").show();
        $("#contact-pro").hide();
        $("#pro-com-consent").hide();
        $("#main-contact-form").addClass("col-xl-7");
    }
    else if (status === "Professionnel") {
        $("#contact-actions").show();
        $("#contact-part").show();
        $("#com-consent").show();
        $("#contact-pro").show();
        $("#pro-com-consent").show();
        $("#main-contact-form").addClass("col-xl-7");
    }

    // Checks changes on contact status value
    $("#contact-type").change(function() {
        var val = $(this).val();
        if(val === "") {
            $("#contact-actions").hide();
            $("#contact-part").hide();
            $("#com-consent").hide();
            $("#contact-pro").hide();
            $("#pro-com-consent").hide();
            $("#main-contact-form").removeClass("col-xl-7");
        }
        else if (val === "Particulier") {
            $("#contact-actions").show();
            $("#contact-part").show();
            $("#com-consent").show();
            $("#contact-pro").hide();
            $("#pro-com-consent").hide();
            $("#main-contact-form").addClass("col-xl-7");
        }
        else if (val === "Professionnel") {
            $("#contact-actions").show();
            $("#contact-part").show();
            $("#com-consent").show();
            $("#contact-pro").show();
            $("#pro-com-consent").show();
            $("#main-contact-form").addClass("col-xl-7");
        }
    });
});

